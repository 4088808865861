import React from 'react';
import { Link, navigate } from 'gatsby';
import { Language } from '../mlva-types';

interface ProjectNavbarProps {
  language: Language;
  logo: string;
  linkTo: string;
}

export const DesktopProjectNavbar = ({
  logo,
  language,
  linkTo,
}: ProjectNavbarProps) => {
  return (
    <nav
      className="navbar is-spaced is-transparent project-navbar is-hidden-touch"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <Link
          to="/"
          className="navbar-item pl-4 pr-0 py-0"
          state={{ language: language }}
        >
          <img src={logo} alt="navigate to homepage" id="navbar-logo" />
        </Link>
        <a
          role="button"
          className="navbar-burger project-close is-active"
          aria-label="close project"
          aria-expanded="false"
          onClick={() => {
            navigate(linkTo, { state: { language: language } });
          }}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
    </nav>
  );
};
