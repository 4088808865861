import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { PressScanningContent } from '../mlva-types';

interface MobilePressSlideshowProps {
  content: PressScanningContent;
}

const MobilePressSlideshow = ({ content }: MobilePressSlideshowProps) => {
  const { pressTitle, pressImages } = content;

  return (
    <>
      {pressImages?.map((pressImage, index: number) => {
        const image = getImage(pressImage.image);
        if (image === undefined) return;
        return (
          <div key={index} className={`px-5 py-2`}>
            <GatsbyImage
              image={image}
              alt={pressTitle + ' | image ' + (index + 1)}
              className="press-slide"
              objectFit="contain"
            />
          </div>
        );
      })}
    </>
  );
};

export default MobilePressSlideshow;
