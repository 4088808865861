import React, { MouseEventHandler } from 'react';

const useOutsideClick = (
  callback: MouseEventHandler,
  excludedTargets: string[] = []
) => {
  const ref = React.useRef();

  React.useEffect(() => {
    const handleClick = (event: any) => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !excludedTargets.includes(event?.target?.id) &&
        !excludedTargets.includes(event?.target?.className)
      ) {
        callback(event);
      }
    };

    document.addEventListener('click', handleClick, true);

    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [ref, callback, excludedTargets]);

  return ref;
};

export default useOutsideClick;
