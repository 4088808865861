import React from 'react';
import { Link, navigate } from 'gatsby';
import { getLanguageFromLocation } from '../hooks/useTranslation';
import { Language, LinkLocation, PressScanningContent } from '../mlva-types';
import useScrollDetected, { DirectionEnum } from '../hooks/useDetectScroll';
import MobilePressSlideshow from './mobile-press-slideshow';

interface MobilePressProps {
  data: {
    pressScanning: {
      frontmatter: PressScanningContent;
    };
    logo: {
      frontmatter: {
        logo: {
          publicURL: string;
        };
      };
    };
  };
  location: LinkLocation;
}

const MobilePress = ({ data, location }: MobilePressProps) => {
  const scrollDetected = useScrollDetected(50, 10);

  const language: Language = getLanguageFromLocation(location);

  const content = data.pressScanning?.frontmatter;

  return (
    <>
      <nav
        className={`navbar is-transparent is-fixed-top is-unselectable ${
          scrollDetected.direction == DirectionEnum.Down
            ? 'navbar-hide'
            : 'navbar-display'
        }`}
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <Link
            to="/"
            className="navbar-item px-4 py-0"
            state={{ language: language }}
          >
            <img
              src={data.logo.frontmatter.logo?.publicURL}
              alt="navigate to homepage"
              id="navbar-logo"
            />
          </Link>
          <a
            role="button"
            className="navbar-burger mr-2 is-active"
            aria-label="close project"
            aria-expanded="false"
            onClick={() => {
              navigate('/about', { state: { language: language } });
            }}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
      </nav>
      <section>
        <div className="after-navbar">
          <MobilePressSlideshow content={content} />
        </div>
      </section>
    </>
  );
};

export default MobilePress;
