import React from 'react';
import './mystyles.scss';
import { graphql, navigate } from 'gatsby';
import { getLanguageFromLocation } from '../hooks/useTranslation';
import { LinkLocation, PressScanningContent } from '../mlva-types';
import PressSlideshow from '../components/press-slideshow';
import MobilePress from '../components/mobile-press';
import { DesktopProjectNavbar } from '../components/project-navbar';
import { SEO } from '../components/seo';

interface PressScanningProps {
  data: {
    pressScanning: {
      frontmatter: PressScanningContent;
    };
    logo: {
      frontmatter: {
        logo: {
          publicURL: string;
        };
      };
    };
  };
  location: LinkLocation;
}

const PressScanning = ({ data, location }: PressScanningProps) => {
  const language = getLanguageFromLocation(location);

  const content = data.pressScanning?.frontmatter;

  return (
    <>
      <div className="is-hidden-desktop">
        <MobilePress data={data} location={location} />
      </div>
      <DesktopProjectNavbar
        logo={data.logo.frontmatter.logo?.publicURL}
        language={language}
        linkTo="/about"
      />
      <section className="columns is-mobile is-multiline press is-hidden-touch">
        <div className="column is-1"></div>
        <div className="column is-hidden-touch" id="press-main">
          <PressSlideshow content={content} />
        </div>
        <div className="column is-1">
          <a
            role="button"
            className="project-close navbar-burger mr-2 is-active"
            aria-label="close project"
            aria-expanded="false"
            onClick={() => {
              navigate('/about', { state: { language: language } });
            }}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div className="column is-12"></div>
      </section>
    </>
  );
};

export default PressScanning;

export const Head = ({ data }: any) => (
  <SEO title={`MLVA | ${data.pressScanning.frontmatter.pressTitle}`} />
);

export const PressScanningQuery = graphql`
  query PressScanningPage($id: String) {
    pressScanning: markdownRemark(id: { eq: $id }) {
      frontmatter {
        pressTitle
        pressImages {
          image {
            childImageSharp {
              gatsbyImageData(quality: 100)
            }
          }
        }
      }
    }
    logo: markdownRemark(
      frontmatter: { templateKey: { eq: "homepage-section" } }
    ) {
      frontmatter {
        logo {
          publicURL
        }
      }
    }
  }
`;
