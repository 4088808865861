import { useEffect, useRef } from 'react';

export enum Arrow {
  Left = 'ArrowLeft',
  Right = 'ArrowRight',
}

const useArrowPressed = (callback: (arrow: Arrow) => void) => {
  const ref = useRef();

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === Arrow.Left || e.key === Arrow.Right) callback(e.key);
    };

    document.addEventListener('keydown', handleKeyDown, true);

    return () => {
      document.removeEventListener('keydown', handleKeyDown, true);
    };
  }, [ref, callback]);

  return ref;
};

export default useArrowPressed;
