import React, { MouseEventHandler, useMemo } from 'react';
import useOutsideClick from '../hooks/useOutsideClick';
import useArrowPressed, { Arrow } from '../hooks/useArrowPressed';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { PressScanningContent, ScannedImage } from '../mlva-types';

const PressSlideshow = ({ content }: { content: PressScanningContent }) => {
  const { pressTitle, pressImages } = content;
  const totalSlides: number | undefined = pressImages?.length;

  const [prevSlideIndex, setPrevSlideIndex] = React.useState(-1);
  const [slideIndex, setSlideIndex] = React.useState(0);
  const [next, setNext] = React.useState(false);
  const [clicked, setClicked] = React.useState(false);

  React.useEffect(() => {
    if (next && clicked) {
      setPrevSlideIndex(slideIndex);
      changeSlide(slideIndex + 1);
    } else if (!next && clicked) {
      setPrevSlideIndex(slideIndex);
      changeSlide(slideIndex - 1);
    }
    setClicked(false);
  }, [clicked]);

  const changeSlide = (newIndex: number) => {
    if (newIndex >= totalSlides) setSlideIndex(0);
    else if (newIndex < 0) setSlideIndex(totalSlides - 1);
    else setSlideIndex(newIndex);
  };

  const handleClickOutside: MouseEventHandler = (e: any) => {
    if (e.x < (e?.view?.innerWidth || 0) / 2) {
      setNext(false);
      setClicked(true);
    } else {
      setNext(true);
      setClicked(true);
    }
  };

  const handleDotClicked = (newIndex: number) => {
    setPrevSlideIndex(slideIndex);
    setSlideIndex(newIndex);
  };

  const handleArrowPressed = (keyPressed: Arrow) => {
    if (keyPressed === Arrow.Left) {
      setNext(false);
      setClicked(true);
    } else if (keyPressed === Arrow.Right) {
      setNext(true);
      setClicked(true);
    }
  };

  const refMouse = useOutsideClick(handleClickOutside, [
    'avoid-slideshow',
    'scrolling-arrow',
    'project-publisher',
    'toggle-project-details',
  ]);
  const refArrow = useArrowPressed(handleArrowPressed);

  const getSlideStateClassName = (index: number) => {
    if (index === slideIndex) return 'active-slide';
    else if (index === prevSlideIndex) return 'become-inactive-slide';
    else return 'inactive-slide';
  };

  const getDots = useMemo(() => {
    let dots = [];
    for (let i = 0; totalSlides && i < totalSlides; i++) {
      dots.push(
        <span
          className={`dot ${i == slideIndex ? 'active' : ''}`}
          onClick={() => handleDotClicked(i)}
        ></span>
      );
    }
    return dots;
  }, [totalSlides, slideIndex]);

  return (
    <>
      <div
        className="columns is-vcentered layout-padding is-unselectable"
        id="project-main-content"
      >
        <div
          className="column is-justify-content-center is-flex is-hidden-mobile"
          id="project-slideshow"
        >
          {pressImages?.map((scannedImage: ScannedImage, index: number) => {
            const image = getImage(scannedImage.image);
            if (image == undefined) return;
            return (
              <div
                key={index}
                className={`carousel-slide project-container ${getSlideStateClassName(
                  index
                )}`}
              >
                <GatsbyImage
                  image={image}
                  alt={pressTitle + ' | photograph ' + (index + 1)}
                  className="project-slide"
                  objectFit="contain"
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="column is-12">
        <div className="has-text-centered">{getDots}</div>
      </div>
      <div ref={refArrow} />
      <div ref={refMouse} />
    </>
  );
};

export default PressSlideshow;
